import service from './request'

export const apiHttp = 'https://be.aszhilian.cn'
const h5Http = 'https://h5.aszhilian.cn'
// export const apiHttp = 'https://zl-cp.dpyouhui.com'
// const h5Http = 'https://zl-h5.dpyouhui.com'

export function wxLogin () {
    if(process.env.NODE_ENV === 'development'){
        window.location.replace(`${apiHttp}/wechat/user/login?callback=http://localhost:9527/`)
    }else{
        window.location.replace(`${apiHttp}/wechat/user/login`)
    }
}
export function toIndex () {
    if(process.env.NODE_ENV === 'development'){
        window.location.replace('http://localhost:9527/#/monitor')
    }else{
        window.location.replace(`${h5Http}/#/monitor`) 
    }
}
export const home = {
    info: (params) => {
        return service.get('/api/space/info', { params })
        // return service({
        //     url: 'api/space/info',
        //     method: 'get',
        //     params
        // })
    },
    spaceSet: (data) => {
        return service.post(`/api/space/setting`, data)
    },
    spaceGet: () => {
        return service.get(`/api/space/setting`)
    }
}

export const common = {
    contact: () => {
        return service.get('/api/common/dict?name=contact')
    },
    jssdkConfig: (params) => {
        return service.get('/api/wechat/jssdkConfig', {params})
    },
    sms: (data) => {
        return service.post('/api/common/sms', data)
    },
    checkSubscribe: () => {
        return service.get('/api/wechat/checkSubscribe')
    },
    upload: (formData) => {
        // return service.post('/api/common/upload', data, {
        //     headers: {
        //       'Content-Type': 'application/x-www-form-urlencoded'
        //     }
        // })
        return service.post('/api/common/cosUpload', formData)
    },
    restLogin: () => {
        return service.get('/api/login/reset')
    }
}

export const user = {
    get_info: () => {
        return service.get('/api/profile/get')
    },
    update_info: (data) => {
        return service.post('/api/profile/set', data)
    },
    change_mobile: (data) => {
        return service.post('/api/profile/changeMobile', data)
    },
    get_total: () => {
        return service.get('/api/profile/total')
    },
    invite_friend:(data) => {
        return service.post('/api/invite/relate', data)
    },
    firend_list: () => {
        return service.get('/api/friend/list')
    },
    list_collect: (params) => {
        return service.get('/api/favorite/list', {params})
    },
    add_collect: (data) => {
        return service.post('/api/favorite/add', data)
    },
    delete_collect: (params) => {
        return service.delete('/api/favorite/delete', {params})
    },
    vip_price: () => {
        return service.get('/api/vip/prices')
    },
    vip_users: () =>{
        return service.get('/api/vip/recentlyUsers')
    },
    vip_trial:() => {
        return service.post('/api/vip/trial')
    },
    vip_order: (data) => {
        return service.post('/api/vip/order', data)
    },
    qrcode: (params) => {
        return service.get('/api/space/qrcode', {params})
        
    }
}

export const poster = {
    list: (params) => {
        return service.get('/api/user_poster/list', {params})
    },
    detail: (params) => {
        return service.get('/api/user_poster/read', { params })
    },
    add: (data) => {
        return service.post('/api/user_poster/create', data)
    },
    edit: (data) => {
        return service.put('/api/user_poster/update', data)
    },
    delete: (params) => {
        return service.delete('/api/user_poster/delete', {params})
    }
}

export const module = {
    list: (params) => {
        return service.get('/api/poster_template/list', {params})
    },
    detail: (params) => {
        return service.get('/api/poster_template/read', {params})
    },
    add: (data) => {
        return service.post('/api/poster_template/create', data)
    },
    edit: (data) => {
        return service.put('/api/poster_template/update', data)
    },
    delete: (params) => {
        return service.delete('/api/poster_template/delete', {params})
    },
    read: (params) => {
        return service.get('/api/poster_template/read', {params})
    },
}

export const aiHelper = {
    list: (params) =>{
        return service.get('/api/aibot/list', {params})
    },
    detail: (params) =>{
        return service.get('/api/aibot/read', {params})
    },
    chart_send: (data) => {
        return service.post('/api/chat/send', data)
    },
    chart_history: (params) => {
        return service.get('/api/chat/histories', {params})
    },
}

export const reward = {
    record: (params) =>{
        return service.get('/api/money/awardLogs', {params})
    },
    withdraw: () => {
        return service.get('/api/withdraw/info')
    },
    post_draw: (data) => {
        return service.post('/api/withdraw/apply', data)
    },
    draw_list: () => {
        return service.get('/api/withdraw/logs')
    }
}